<template>
    <div>

        <div class="wrap p-child-wait">

            <layout-header title="" btn-left="back" bg-color="#113932"></layout-header>

            <div class="container">
                <div class="content">
                    <div class="child-info">
                        <div class="img-box">
                            <img :src="profileSrc" alt="">
                        </div>
                        <div class="info-box">
                            <span><strong>{{ nickName }}</strong> 어린이</span>
                            <span class="slash">/</span>
                            <span><strong>{{ teamName }}</strong></span>
                        </div>
                    </div>
                    <div class="wait-text">승인 대기 중입니다.</div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import commonUtils from "@/commons/utils/common.utils";

export default {
    name: "profileAuth",
    components: {LayoutHeader},
    data() {
        let caidx = this.$route.query.caidx
        return {
            caidx,
            auth: false,
            nickName: '',
            teamName: '',
            coEmailId: '',
            profileSrc: ''
        }
    },
    created() {
        this.requireLoginMainWithoutAlert()
    },
    mounted() {
        this.getProfile()
    },
    methods: {

        getProfile() {

            const _ = window._
            const self = this

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getProfileInfo', {
                caidx: self.caidx
            }, (res) => {

                console.log(res)

                self.profileSrc = res.data.profileAccount.profileSrc
                self.nickName = res.data.profileAccount.nickName
                self.coEmailId = res.data.profileAccount.coEmailId

                if (commonUtils.nullCheck(res.data.profileAccount.coInfoVO)) {
                    res.data.profileAccount.coInfoVO = {}
                    res.data.profileAccount.coInfoVO.coName = "반 배정 없음"
                }

                self.teamName = res.data.profileAccount.coInfoVO.coName

                if (res.data.profileAccount.authEmail) {
                    self.goEdu()
                }

            })
        },

        goEdu() {
            const self = this
            self.$router.push({
                name: "courseList",
            })
        }

    },
    watch: {
    }

}
</script>

<style scoped>

</style>